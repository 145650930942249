import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import api, { resourceCreator } from '../../api';
// eslint-disable-next-line import/no-cycle
import allSchema from '../../components/masterData/schemas/entitySchemas';
import Constants from '../../Constants';

export const entityManager = createSlice({
  name: 'entityManager',
  initialState: {
    saveError: null,
    saveSuccess: false,
    saveSuccessMessage: null,
    data: {},
  },
  reducers: {
    setSaveSuccess: (state, action) => {
      state.saveSuccess = action.payload;
      state.saveError = null;
    },
    setSaveError: (state, action) => {
      state.saveError = action.payload ? get(JSON.parse(action.payload), 'title') : null;
      state.saveSuccess = false;
      state.saveSuccessMessage = null;
    },
    registerEntity: (state, action) => {
      const { entityType, entityDefinition } = action.payload;
      if (!(entityType in state.data)) {
        state.data[entityType] = {
          entityDefinition,
          entities: [],
        };
      } else {
        state.data[entityType].entityDefinition = entityDefinition;
      }
    },
    registerEntities: (state, action) => {
      const { entities } = action.payload;
      entities.forEach(entityDefinition => {
        const entityType = entityDefinition.name;
        if (!(entityType in state.data)) {
          state.data[entityType] = {
            entityDefinition,
            entities: [],
          };
        } else {
          state.data[entityType].entityDefinition = entityDefinition;
        }
      });
    },
    addEntity: (state, action) => {
      const { entityType, entity } = action.payload;
      state.data[entityType].entities = [...state.data[entityType].entities, entity];
    },
    addEntities: (state, action) => {
      const { entityType, entities } = action.payload;
      if (!state.data[entityType]) {
        // console.log(state.data);
      }
      state.data[entityType].entities = [
        ...state.data[entityType].entities,
        ...(Array.isArray(entities) ? entities : [entities]),
      ];
    },
    editEntity: (state, action) => {
      const { entityDef, entity, entityid } = action.payload;
      const entityType = entityDef.name;
      const { systemIdField } = entityDef;
      state.data[entityType].entities = state.data[entityType].entities.map(e =>
        e[systemIdField] === entityid ? entity : e
      );
    },
    deleteEntity: (state, action) => {
      const { entityDef, entityid } = action.payload;
      const entityType = entityDef.name;
      const { systemIdField } = entityDef;
      state.data[entityType].entities = state.data[entityType].entities.filter(e => e[systemIdField] !== entityid);
    },
    clearEntity: (state, action) => {
      const { entityType } = action.payload;
      if (state.data[entityType]) {
        state.data[entityType].entities = [];
      }
    },
    clearEntities: state => {
      Object.keys(state.data).forEach(k => delete state.data[k]);
    },
    setSaveSuccessMessage: (state, action) => {
      state.saveSuccessMessage = action.payload;
      state.saveError = null;
    },
  },
});

// Actions.
export const {
  registerEntity,
  registerEntities,
  addEntity,
  addEntities,
  editEntity,
  deleteEntity,
  clearEntities,
  clearEntity,
  setSaveError,
  setSaveSuccess,
  setSaveSuccessMessage,
} = entityManager.actions;

export const fetchEntity = entityDef => async dispatch => {
  const { name } = entityDef;
  const path = entityDef.path || `/${entityDef.name}s`;
  if (!(name in api)) {
    api.registerResources(
      resourceCreator({
        name,
        path,
      })
    );
  }
  const response = await api[name].list();
  const json = await Constants.handleErrors(response, dispatch);
  dispatch(clearEntity({ entityType: name }));
  dispatch(addEntities({ entityType: name, entities: json }));
  return json;
};

const createAPIResource = entityDef => {
  const { name } = entityDef;
  const path = entityDef.path || `/${entityDef.name}s`;
  if (!(name in api)) {
    api.registerResources(
      resourceCreator({
        name,
        path,
      })
    );
  }
};

const createSuccessMessage = (entityDef, json) => {
  let successMessage = '';
  if (json !== undefined && (entityDef?.autoUid !== undefined || entityDef?.systemIdField !== undefined)) {
    const { systemIdField } = entityDef;
    const name = get(entityDef, 'displayName', entityDef.name);
    const entity = name.charAt(0).toUpperCase() + name.slice(1);
    const uid = json[systemIdField];
    successMessage = `${entity} was successfully saved under ${systemIdField} ${uid}`;
  }
  return successMessage;
};

export const postEntity =
  ({ entityDef, entity }) =>
  async dispatch => {
    createAPIResource(entityDef);
    const isMultiple = entityDef.createMultiple;
    const payload = isMultiple ? [entity] : entity;
    const response = await api[entityDef.name].create(payload);
    const json = await Constants.handleErrors(response, dispatch, setSaveError);
    const resultEntity = isMultiple ? json[0] : json;
    dispatch(addEntity({ entityType: entityDef.name, entity: resultEntity }));
    dispatch(setSaveSuccessMessage(createSuccessMessage(entityDef, json)));
    dispatch(setSaveSuccess(true));
    return json;
  };

export const patchEntity =
  ({ entityDef, entity, entityid }) =>
  async dispatch => {
    createAPIResource(entityDef);
    const response = await api[entityDef.name].patch({ id: entityid }, entity);
    const json = await Constants.handleErrors(response, dispatch, setSaveError);
    dispatch(editEntity({ entityDef, entity: json, entityid }));
    dispatch(setSaveSuccess(true));
    return json;
  };

export const delEntity =
  ({ entityDef, entityid }) =>
  async dispatch => {
    createAPIResource(entityDef);
    const response = await api[entityDef.name].delete({ id: entityid });
    const json = await Constants.handleErrors(response, dispatch, setSaveError);
    dispatch(deleteEntity({ entityDef, entityid }));
    dispatch(setSaveSuccess(true));
    return json;
  };

export const fetchEntitiesData = entities => async (dispatch, getState) => {
  // Default entities to fetch.
  const targetEntities = entities || [
    'contract',
    'input_product',
    'grinder',
    'packer_plant',
    'packer',
    'freight_rate',
    'transporter',
    'load_size',
    'unit',
    'port',
    'fec',
    'coldstore',
    'form_fields',
    'shipping_line',
    'voyage_leg',
    'po_series',
    'end_user',
    'group_housed_premium_configs',
    'load_notes',
    'price_agreements',
  ];

  // Check cached entity data.
  const { data } = getState().entityManager;
  allSchema.forEach(ent => {
    if (targetEntities.includes(ent.name) && !data[ent.name]) {
      dispatch(registerEntity({ entityType: ent.name, entityDefinition: ent }));
      dispatch(fetchEntity(ent));
    }
  });
};

// Selectors.
export const selectEntities = state => state.entityManager.data;

export const selectSaveError = state => state.entityManager.saveError;

export const selectSaveSuccess = state => state.entityManager.saveSuccess;

export const selectSaveSuccessMessage = state => state.entityManager.saveSuccessMessage;

/*
 The selector way of getting entities.
 allEntities = useSelector(selectEntities);
 requiredEnttities = findEntities(allEntities);
*/

export const findEntities = (data, entities, includeDelisted = true) => {
  // Default entities to map to props.
  const targetEntities = entities || {
    business_offices: ['form_fields', 'business_office'],
    cold_stores: 'coldstore',
    configs: 'config',
    specifications: 'specification',
    container_depots: ['form_fields', 'container_depot'],
    container_returns: ['form_fields', 'container_return'],
    containers: ['form_fields', 'container'],
    contracts: 'contract',
    currencies: ['form_fields', 'currency'],
    fecs: 'fec',
    freight_rate: 'freight_rate',
    grinders: 'grinder',
    inco_terms: ['form_fields', 'incoterms'],
    input_products: 'input_product',
    load_sizes: 'load_size',
    packer_plants: 'packer_plant',
    packers: 'packer',
    po_doc_types: ['form_fields', 'po_doc_type'],
    ports: 'port',
    price_types: ['form_fields', 'price_type'],
    reason_for_rejection: ['form_fields', 'reason_for_rejection'],
    shipping_lines: 'shipping_line',
    tags: ['form_fields', 'tags'],
    transporters: 'transporter',
    units_of_measure: 'unit',
    voyage_legs: 'voyage_leg',
    end_users: 'end_user',
    surcharge_types: ['form_fields', 'surcharge'],
    business_use: ['form_fields', 'business_use'],
    custom_po_docs: ['form_fields', 'custom_po_docs'],
    group_housed_premium_configs: 'group_housed_premium_configs',
    issues_xcode: 'load_notes',
    price_agreements: 'price_agreements',
  };

  return Object.entries(targetEntities).reduce((acc, [key, value]) => {
    const placeholder = {};
    if (Array.isArray(value)) {
      // Form fields has a sub type.
      placeholder[key] = get(data, `${value[0]}.entities`, []).filter(ent => ent.type === value[1]);
    } else {
      placeholder[key] = includeDelisted
        ? get(data, `${value}.entities`, []).filter(ent => !ent.delisted)
        : get(data, `${value}.entities`, []);
    }
    return { ...acc, ...placeholder };
  }, {});
};

export const findEntitiesInState = (state, entities) => findEntities(state.entityManager.data, entities);
export const findAllEntitiesWithoutFilterInState = (state, entities) =>
  findEntities(state.entityManager.data, entities, false);

// Reducers.
export default entityManager.reducer;
